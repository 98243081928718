import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BreadCrumbs from "../../components/BreadCrumbs";
import DelphiEntwicklungHeader from "../../components/UeberMich/DelphiEntwicklungHeader";
import Teaser1 from "../../components/UeberMich/Teaser1";
import DelphiProjekt from "../../components/UeberMich/DelphiProjekt";


export default function DelphiEntwicklung() {
    const Breadcrumbs = [
        { name: 'Ueber mich', href: '#', current: false },
        { name: 'Delphi-Entwicklung', href: '/UeberMich/DelphiEntwicklung', current: true },
    ]

    return (
        <>
            <Layout>
                <Seo title="Delphi-Entwicklung"/>
                <BreadCrumbs pages={Breadcrumbs}/>

                <DelphiEntwicklungHeader />

                <Teaser1 headline1="Sie wollen eine Windows-Applikation?" headline2="Lassen Sie uns möglichst bald über Ihr Projekt sprechen!" buttonText="Kontakt" url="/kontakt"/>

                <DelphiProjekt />


            </ Layout>
        </>
    )
}