import * as React from "react";
import {Link} from "gatsby";

export default function Teaser1({headline1, headline2, buttonText, url}) {
    return (
        <div className="bg-diit">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
                <h2 className="text-3xl font-extrabold tracking-tight text-white md:text-4xl">
                    <span className="block">{headline1}</span>
                    <span className="block text-xl font-medium text-blue-200">{headline2}</span>
                </h2>
                <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                    <div className="inline-flex rounded-md shadow">
                        <Link
                            to={url}
                            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                        >
                            {buttonText}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
