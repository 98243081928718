/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./layout.css"
import "./inter.css"
import Navigationsleiste from "./Navigationsleiste";
import Footer from "./Footer";
import {Helmet} from "react-helmet";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
        <Helmet htmlAttributes={{ lang: 'de-DE' }} />

        <div className="relative h-full bg-gray-100 font-sans">
            <Navigationsleiste />

            <main>{children}</main>
        </div>
        <Footer ></Footer>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
