import * as React from "react";

import { ViewGridAddIcon, TableIcon, CodeIcon, LibraryIcon, SwitchHorizontalIcon } from '@heroicons/react/outline'
import {StaticImage} from "gatsby-plugin-image";

const backendItems = [
    {
        id: 1,
        name: 'Rapid Development',
        description:
            'Für mich die schnellste und damit günstigste Art, Windows-Programme zu erstellen. Davon profitieren ' +
            'vor allem meine Kunden.',
        icon: SwitchHorizontalIcon,
    },
    {
        id: 2,
        name: 'DevExpress-Komponenten',
        description:
            'Die DevExpress-Komponenten liefern so ziemlich alles, was man als Windows-Programmentwickler benötigt.',
        icon: LibraryIcon,
    },
    {
        id: 3,
        name: 'Support von Internet Technologien',
        description:
            'Delphi bietet von Haus aus gute Unterstützung, um Internet-Technologien anzubinden. Auch das spart Zeit und damit Geld.',
        icon: CodeIcon,
    },
]

export default function DelphiEntwicklungHeader() {
    return (
        <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
            <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <svg
                    className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
                    width={404}
                    height={784}
                    fill="none"
                    viewBox="0 0 404 784"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
                </svg>

                <div className="relative">
                    <h1 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        Delphi-Entwicklung
                    </h1>
                    <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                        Warum Sie Delphi als Entwicklungsplattform ernsthaft in Erwägung ziehen sollten
                    </p>
                </div>

                <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                    <div className="relative">
                        <h2 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                            Drei Gründe für Delphi-Entwicklung
                        </h2>
                        <p className="mt-3 text-lg text-gray-500">
                            Delphi führt meines Erachtens zu Unrecht ein Rand-Dasein. Für mich ist Delphi als Entwicklungsumgebung
                            zusammen mit den DevExpress-Komponenten die perfekte Kombination, wenn man schnell ein
                            gutes Windows-Programm erstellen möchte.
                        </p>

                        <dl className="mt-10 space-y-10">
                            {backendItems.map((item) => (
                                <div key={item.id} className="relative">
                                    <dt>
                                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-diit text-white">
                                            <item.icon className="h-6 w-6" aria-hidden="true" />
                                        </div>
                                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                                    </dt>
                                    <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>

                    <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                        <svg
                            className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                            width={784}
                            height={404}
                            fill="none"
                            viewBox="0 0 784 404"
                        >
                            <defs>
                                <pattern
                                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
                        </svg>


                        <StaticImage
                            src="../../images/delphi-app.jpg"
                            formats={["auto", "webp", "avif"]}
                            alt="Schickes Delphi-Programm"
                            width={490}
                            loading="eager"
                            className="relative mx-auto filter drop-shadow-2xl"
                        />

                    </div>
                </div>

                <svg
                    className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
                    width={404}
                    height={784}
                    fill="none"
                    viewBox="0 0 404 784"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
                </svg>


            </div>
        </div>
    )
}
