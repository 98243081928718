import * as React from "react";
import { CheckIcon } from '@heroicons/react/outline'

const features = [
    {
        name: 'Elegantes Aussehen',
        description: 'Sie werden Ihre Kunden mit dem Aussehen Ihrer Anwendung beeindrucken. Das Auge kauft mit!',
    },
    {
        name: 'Kurze Entwicklungszeit',
        description: 'Dank Delphi und DevExpress wird Ihre Anwendung in Rekordzeit fertig. So sparen Sie bares Geld.',
    },
    {
        name: 'Gute Performance',
        description: 'Alle Komponenten sind auf Geschwindigkeit getrimmt. So kommt keine Wartezeit auf.'
    },

    { name: 'Einfach zu bedienen', description: 'Ich mache Ihnen kreative Vorschläge, damit Anwender Ihre Anwendung lieben werden.' },
    { name: 'Intelligente Daten-Analyse', description: 'Vom Dashboard bis zur Pivot-Tabelle, ich liefere alles für Ihre Datenanalyse.' },
    { name: 'Ihre Kunden beeindrucken', description: 'Clevere Konzepte, gut umgesetzt. Gerne berate ich Sie in Sachen User Experience.' },
    { name: 'Datenbank-Flexibilität', description: 'Wir unterstützen die gängigsten Datenbanken. Sprechen Sie mich darauf an.' },
    { name: 'Gute Kommunikation', description: 'Ich habe immer ein offenes Ohr für Sie und Ihre Anforderungen. Auf gute Zusammenarbeit!' },
]

export default function DelphiProjekt() {
    return (
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
                <div>
                    <h2 className="text-base font-semibold text-diit uppercase tracking-wide">Alles, was Sie brauchen</h2>
                    <h3 className="mt-2 text-3xl font-extrabold text-gray-900">Schicke Software — schnell!</h3>
                    <p className="mt-4 text-lg text-gray-500">
                        Das können Sie alles haben, wenn Sie mit mir ein Delphi-Projekt realisieren.
                    </p>
                </div>
                <div className="mt-12 lg:mt-0 lg:col-span-2">
                    <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative">
                                <dt>
                                    <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                                    <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                                </dt>
                                <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}
